.layers {
  display: inline-block;
  position: absolute !important;
  bottom: 2.5em;
  left: 55px;
  z-index: 1 !important;
  /*padding: 0.25em;*/
}

/* Dropdown Button */
.dropbtn {
  color: #333;
  padding: 0.3em 0.52em;
  font-size: 0.9em;
  background: #fff;
  box-shadow: 0 0 24px -12px black;
  border-radius: 3px;
}

.dropdown button {
  display: inline-block;
  border: none;
  cursor: pointer;
  outline: inherit;
}

button > svg {
  pointer-events: none;
}

/* Dropdown button on hover & focus */
.dropbtn:hover,
.dropbtn:focus {
  background-color: #f3f3f3;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  bottom: 2.75em;
  background-color: #f1f1f1;
  min-width: 10em;
  border-radius: 3px;
  box-shadow: 0 0 24px -12px black;
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content label {
  color: black;
  width: 100%;
  text-align: left;
  font-size: 0.9em;
  /*padding: 12px 16px;*/
  padding: 0.5em 0.75em;
  text-decoration: none;
  /*display: block;*/
}

/* Change color of dropdown links on hover */
.dropdown-content label:hover {
  background-color: #ddd;
}

.dropdown-content label:first-child {
  border-radius: 3px 3px 0 0;
}

.dropdown-content label:last-child {
  border-radius: 0 0 3px 3px;
}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {
  display: block;
}
