#logo {
  max-height: 1.5em;
  margin-right: 0.5em;
}

.navbar-toggler.collapsed {
  transform: none;
}

nav, .dropdown-menu {
  background-color: #070a24 !important;
}

nav svg {
  padding: 0 0.35em 0 0;
}
