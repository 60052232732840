.toolbar-container {
  display: inline-block;
  position: absolute;
  top: 56px;
  left: 10px;
  box-shadow: 0 0 24px -12px black;
  text-align: center;
  z-index: 1 !important;
}

.toolbar:last-of-type {
  margin-top: 8px;
}

.toolbar-active {
  background-color: #070a24 !important;
}

.toolbar-active button {
  color: white !important;
}

.toolbar div {
  background-color: white;
}

.toolbar div:not(:last-child) {
  border-bottom: 1px solid #ddd;
}

.toolbar div:first-child {
  border-radius: 3px 3px 0 0;
}

.toolbar div:last-child {
  border-radius: 0 0 3px 3px;
}

.toolbar button {
  display: inline-block;
  color: #070a24;
  padding: 0.32em 0.38em;
  font-size: 0.9em;
  background: none;
  border: none;
  cursor: pointer;
  outline: inherit;
}

.toolbar button:hover {
  color: #070a24;
}

.toolbar div:hover {
  background-color: #f3f3f3;
}
