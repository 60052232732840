.sidebar {
  transition: transform 0.5s;
  z-index: 1;
  top: 1em;
  bottom: 3em;
}

@media (max-width: 575.98px) {
  .sidebar {
    width: 85%;
  }
}

@media (min-width: 575.98px) {
  .sidebar {
    width: 26em;
  }
}

.rounded-rect {
  background: white;
  border-radius: 3px;
  box-shadow: 0 0 50px -25px black;
}

.flex-center.right {
  right: 0px;
}

.flex-center {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar-container {
  position: absolute;
  top: 43px;
  width: 95%;
  height: 95%;
}

.sidebar-content {
  width: 100%;
  height: 100%;
  padding: 2em;
}

.sidebar-toggle {
  background-color: white;
  border-radius: 3px 0 0 3px;
  box-shadow: 0 0 50px -25px black;
  position: absolute;
  width: 1.2em;
  height: 3em;
  overflow: visible;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

.sidebar-toggle.right {
  left: -1.2em;
}

.sidebar-toggle:hover {
  color: black;
  cursor: pointer;
}

.collapsed {
  transform: translateX(29.3em);
}

.sidebar h2 {
  margin-bottom: 1em;
}
