table.statistics th {
  text-align: center;
}

table.statistics td:nth-of-type(n + 1) {
  text-align: center;
}

table.statistics td:first-of-type {
  text-align: right;
  font-weight: bold;
}
